.DateInput {
  width: 85px;
  margin-left: 18px;
  margin-right: 2px;

  &_input {
    font-size: 14px;
    color: #3a3b3f;
    font-weight: 400;

    &__regular {
      padding: 6px 0px 6px 0px;
    }

    &__focused {
      border-bottom-color: #2e3552;
    }
  }

  &_fang {
    z-index: 7;
  }
}

.DateRangePicker_picker {
  z-index: 6;
}

.DateRangePickerInput {
  + .DateInput {
    margin-left: 0;
  }
  &_calendarIcon {
    padding: 5px 8px 3px;
    margin: 0;
    outline: none;
  }

  &__withBorder {
    border-radius: 4px;
    border-color: #dfe3e8;
  }
}

.CalendarDay {
  &__selected {
    background: #1665d8;
    border-color: rgba(22, 101, 216, 0.1);

    &:hover {
      background: #1665d8;
    }

    &_span {
      background-color: rgba(22, 101, 216, 0.1);
      color: #3a3b3f;
      border-color: rgba(22, 101, 216, 0.1);

      &:hover {
        background: #1665d8;
        border-color: rgba(22, 101, 216, 0.1);
      }
    }
  }

  &__hovered {
    &_span {
      background-color: rgba(22, 101, 216, 0.1);
      color: #3a3b3f;
      border-color: rgba(22, 101, 216, 0.1);
      &:hover {
        background: #1665d8;
        border-color: rgba(22, 101, 216, 0.1);
        color: white;
      }
    }
  }
}

.DateRangePickerInput_calendarIcon + .DateInput {
  margin-left: 0;
}

.CalendarMonth_caption {
  padding-bottom: 44px;
}

@media screen and (max-width: 599px) {
  .DateRangePicker_picker {
    transform: scale(0.8);
    transform-origin: top right;
  }
}

.DayPickerKeyboardShortcuts_show__bottomRight {
  &:hover:before {
    border-right-color: #1665d8;
  }
  &:before {
    border-right-color: #2e3552;
  }
}

.error {
  .SingleDatePicker {
    .SingleDatePickerInput__withBorder {
      border-color: #f44336;
    }

    .DateInput_input::placeholder {
      color: #f44336;
    }
  }
}
